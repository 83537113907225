<template>
    <div class="box">
    <el-dialog v-model="visibleDrawer" title="售后登记" width="350px" @opened="returnData()">
        <el-form ref="form" :model="afterMarketData" label-width="auto" style="max-width: 600px">
            <el-form-item label="退货原因" prop="afterReason">
                <el-select v-model="afterMarketData.afterReason" :disabled="orderControll.afterReason" style="width: 195px;">
                    <el-option label="厂家缺货" value="厂家缺货"></el-option>
                    <el-option label="价格问题" value="价格问题"></el-option>
                    <el-option label="配送问题" value="配送问题"></el-option>
                    <el-option label="时间问题" value="时间问题"></el-option>
                    <el-option label="客户自身原因" value="客户自身原因"></el-option>
                    <el-option label="七天无理由退货" value="七天无理由退货"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="退补厂家金额" prop="afterMoney">
                <el-input v-model="afterMarketData.afterMoney" placeholder="不填，则默认为0元" :disabled="orderControll.afterMoney" style="width: 195px;"></el-input>
                <span style="font-size: 24px;">￥</span>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer" v-if="afterMarketData.afterMarket==0">
                <el-button type="primary" @click="afterMarket()">
                登记为售后单
                </el-button>
            </div>
        </template>
    </el-dialog>
    </div>
</template>
<script setup>
import { ref,defineProps } from 'vue'
import {
    afterMarketCheck
} from '@/api/order.js';
import { ElMessage,ElMessageBox } from 'element-plus';

const visibleDrawer = ref(false)

const open=()=>{
    visibleDrawer.value=true
}

const props = defineProps ({
    afterData : String
})

const afterMarketData=ref({
    id:'',
    afterMarket:'',
    afterReason:'',
    afterMoney:'',
    retrieveRemark:'',
    retrieveMoney:''
})
const orderControll=ref({
    afterReason:true,
    afterMoney:true
})

const returnData=async()=>{
    afterMarketData.value=props.afterData
    console.log(afterMarketData.value)
    orderControll.value.afterReason=false
    orderControll.value.afterMoney=false
}

const afterMarket=async()=>{
    ElMessageBox.confirm('登记为售后单后，本单将不可被修改，请确认', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(async () => {
        if(afterMarketData.value.afterMoney==''||afterMarketData.value.afterMoney==null){
            afterMarketData.value.afterMoney='0';
        }
        afterMarketData.value.afterMarket=1;
        afterMarketData.value.retrieveRemark=0;
        afterMarketData.value.retrieveMoney='0';
        const result=await afterMarketCheck(afterMarketData.value)
        if(result.code==200||result.code==0){
            ElMessage.success("登记成功")
            visibleDrawer.value=false
            location.reload()
        }else{
            afterMarketData.value.afterMarket=0;
        }
    })
}

defineExpose({open})
</script>