<template>
    <el-card class="page-container">
        <template #header>
            <div class="header">
                <span>京东账号管理</span>
            </div>
        </template>
        <el-form inline id="topForm">
            <div id="search">
                <el-form-item label="账号名称：">
                    <el-input v-model="accountDTO.accountName" class="inputBar" clearable>
                    </el-input>
                </el-form-item>
            </div>
        </el-form>
        <el-button type="primary" class="button" @click="accountList()">搜索</el-button>
            <el-tabs v-model="activeName" class="demo-tabs" @tab-change="handleClick" type="border-card">
                <el-tab-pane label="南区" name="1">
                <el-table :data="accountInfo" border style="width: 100%; height: 420px;" stripe
                    :header-cell-style="{backgroundColor: 'rgb(239 239 239)',height: '10%'}"
                    :cell-style="{fontSize: 'xx-small'}"
                    :row-style="rowStyle"
                >
                    <el-table-column label="账号id" prop="venderId"></el-table-column>
                    <el-table-column label="账号名称" prop="remark" fixed > </el-table-column>
                    <el-table-column label="原账号名称" prop="jdRename" fixed ></el-table-column>
                    <el-table-column label="订单提醒功能" prop="tips" >
                        <template #default="scope">
                            <div v-if="scope.row.robotUrl!=''&&scope.row.robotUrl!=null&&scope.row.jdapiCookie!=null">
                                可用
                            </div>
                            <div v-else>
                                不可用
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="拥有机器人" prop="tips" >
                        <template #default="scope">
                            <div v-if="scope.row.robotUrl!=''&&scope.row.robotUrl!=null">
                                拥有
                            </div>
                            <div v-else>
                                未拥有
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="账号跳转功能" prop="jumpable" >
                        <template #default="scope">
                            <div v-if="scope.row.cookies!=''&&scope.row.cookies!=null">
                                可用
                            </div>
                            <div v-else>
                                不可用
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="所属运营区" prop="area" >
                        <template #default="scope">
                            <div v-if="scope.row.area==1">
                                南区
                            </div>
                            <div v-else-if="scope.row.area==2">
                                北区
                            </div>
                            <div v-else>
                                未分配
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="排序编号" prop="sort"></el-table-column>
                    <el-table-column label="操作" width="100" fixed="right">
                        <template #default="{ row }">
                            <el-link :underline="false" @click="pluginEdit(row)">
                                编辑
                            </el-link>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty description="没有数据" />
                    </template>
                </el-table>
                <el-pagination 
                    v-model:current-page="accountDTO.pageNo"
                    v-model:page-size="accountDTO.pageSize"
                    layout="prev, pager, next, total" 
                    background
                    :total="total" 
                    @size-change="onSizeChange"
                    @current-change="onCurrentChange"
                    style="justify-content: flex-end; 
                    position: fixed; 
                    bottom: 60px; 
                    width: 80%;"
                />
            </el-tab-pane>
            <el-tab-pane label="北区" name="2">
                <el-table :data="accountInfo" border style="width: 100%; height: 420px;" stripe
                    :header-cell-style="{backgroundColor: 'rgb(239 239 239)',height: '10%'}"
                    :cell-style="{fontSize: 'xx-small'}"
                    :row-style="rowStyle"
                >
                    <el-table-column label="账号id" prop="venderId"></el-table-column>
                    <el-table-column label="账号名称" prop="remark" fixed > </el-table-column>
                    <el-table-column label="原账号名称" prop="jdRename" fixed ></el-table-column>
                    <el-table-column label="订单提醒功能" prop="tips" >
                        <template #default="scope">
                            <div v-if="scope.row.robotUrl!=''&&scope.row.robotUrl!=null&&scope.row.jdapiCookie!=null">
                                可用
                            </div>
                            <div v-else>
                                不可用
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="拥有机器人" prop="tips" >
                        <template #default="scope">
                            <div v-if="scope.row.robotUrl!=''&&scope.row.robotUrl!=null">
                                拥有
                            </div>
                            <div v-else>
                                未拥有
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="账号跳转功能" prop="jumpable" >
                        <template #default="scope">
                            <div v-if="scope.row.cookies!=''&&scope.row.cookies!=null">
                                可用
                            </div>
                            <div v-else>
                                不可用
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="所属运营区" prop="area" >
                        <template #default="scope">
                            <div v-if="scope.row.area==1">
                                南区
                            </div>
                            <div v-else-if="scope.row.area==2">
                                北区
                            </div>
                            <div v-else>
                                未分配
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="排序编号" prop="sort"></el-table-column>
                    <el-table-column label="操作" width="100" fixed="right">
                        <template #default="{ row }">
                            <el-link :underline="false" @click="pluginEdit(row)">
                                编辑
                            </el-link>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty description="没有数据" />
                    </template>
                </el-table>
                <el-pagination 
                    v-model:current-page="accountDTO.pageNo"
                    v-model:page-size="accountDTO.pageSize"
                    layout="prev, pager, next, total" 
                    background
                    :total="total" 
                    @size-change="onSizeChange"
                    @current-change="onCurrentChange"
                    style="justify-content: flex-end; 
                    position: fixed; 
                    bottom: 60px; 
                    width: 80%;"
                />
            </el-tab-pane>
            <el-tab-pane label="未分区" name="10">
                <el-table :data="accountInfo" border style="width: 100%; height: 420px;" stripe
                    :header-cell-style="{backgroundColor: 'rgb(239 239 239)',height: '10%'}"
                    :cell-style="{fontSize: 'xx-small'}"
                    :row-style="rowStyle"
                >
                    <el-table-column label="账号id" prop="venderId"></el-table-column>
                    <el-table-column label="账号名称" prop="remark" fixed > </el-table-column>
                    <el-table-column label="原账号名称" prop="jdRename" fixed ></el-table-column>
                    <el-table-column label="订单提醒功能" prop="tips" >
                        <template #default="scope">
                            <div v-if="scope.row.robotUrl!=''&&scope.row.robotUrl!=null&&scope.row.jdapiCookie!=null">
                                可用
                            </div>
                            <div v-else>
                                不可用
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="拥有机器人" prop="tips" >
                        <template #default="scope">
                            <div v-if="scope.row.robotUrl!=''&&scope.row.robotUrl!=null">
                                拥有
                            </div>
                            <div v-else>
                                未拥有
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="账号跳转功能" prop="jumpable" >
                        <template #default="scope">
                            <div v-if="scope.row.cookies!=''&&scope.row.cookies!=null">
                                可用
                            </div>
                            <div v-else>
                                不可用
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="所属运营区" prop="area" >
                        <template #default="scope">
                            <div v-if="scope.row.area==1">
                                南区
                            </div>
                            <div v-else-if="scope.row.area==2">
                                北区
                            </div>
                            <div v-else>
                                未分配
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="排序编号" prop="sort"></el-table-column>
                    <el-table-column label="操作" width="100" fixed="right">
                        <template #default="{ row }">
                            <el-link :underline="false" @click="pluginEdit(row)">
                                编辑
                            </el-link>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty description="没有数据" />
                    </template>
                </el-table>
                <el-pagination 
                    v-model:current-page="accountDTO.pageNo"
                    v-model:page-size="accountDTO.pageSize"
                    layout="prev, pager, next, total" 
                    background
                    :total="total" 
                    @size-change="onSizeChange"
                    @current-change="onCurrentChange"
                    style="justify-content: flex-end; 
                    position: fixed; 
                    bottom: 60px; 
                    width: 80%;"
                />
            </el-tab-pane>
        </el-tabs>
    </el-card>
	<pluginOperate ref="oprateInfoShow" :accountData="accountData"></pluginOperate>
</template>

<script setup>
import { ref } from 'vue'
import {
    queryAccount
} from '@/api/plugin.js';
import pluginSort from './pluginSort.vue';
import pluginOperate from './pluginOperate.vue'
const pluginSortRef = ref(null);
const activeName=ref('1')

const accountDTO=ref({
    accountName: '',
    area: '1',
    pageNo: 1,
    pageSize: 20
})
const total = ref(null)
const accountInfo=ref([{
    venderId: '',
    appKey: '',
    appSecret: '',
    token: '',
    robotUrl: '',
    remark: '',
    cookies: '',
    jdapiCookie: '',
    jdRename: '',
    sort: '',
    area: ''
}])

const accountData=ref({
    venderId: '',
    appKey: '',
    appSecret: '',
    token: '',
    robotUrl: '',
    remark: '',
    cookies: '',
    jdapiCookie: '',
    jdRename: '',
    sort: '',
    area: ''
})

const handleClick = () => {
    accountDTO.value.area=activeName.value
    accountList();
}
const oprateInfoShow=ref()
const pluginEdit=(row)=>{
	accountData.value=row;
    oprateInfoShow.value.open()
}

const accountList = async()=>{
	// accountDTO.value=props.accountDTO;
	// console.log(accountDTO.value)
    let result=await queryAccount(accountDTO.value);
    if(result.code==200){
        accountInfo.value=result.data.data
        total.value=result.data.totalCount
    }
}
accountList();
//当每页条数发生了变化，调用此函数
const onSizeChange = (size) => {
    accountDTO.value.pageSize = size
    accountList();
}
//当前页码发生变化，调用此函数
const onCurrentChange = (num) => {
    accountDTO.value.pageNo = num
    accountList();
}
</script>

<style lang="scss" scoped>
.page-container {
    min-height: 100%;
    box-sizing: border-box;

    .header {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .el-form-item{
        float: left;
        display: flex;
        flex-direction: row;
    }

    .inputBar{
        width: 120px;
    }
    
    .button{
        float: right;
        margin-top: -35px;
        /* display: flex;
        flex-direction: row-reverse; */
    }
}

#search {
    width:  100%;
    height: 100px
}
.el-link {
    margin-right: 10px;
}
</style>