<template>
    <el-row class="login-page">
        <el-col :span="12" class="bg"></el-col>
        <el-col :span="6" :offset="3" class="form">
            <el-form ref="form" size="large" autocomplete="off" :model="registerData" :rules="rules">
                <el-form-item>
                    <h1>登录</h1>
                </el-form-item>
                <el-form-item prop="username">
                    <el-input :prefix-icon="User" placeholder="输入账号" v-model="registerData.username"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input :prefix-icon="Lock" type="password" placeholder="输入密码" v-model="registerData.password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="button" type="primary" auto-insert-space  @click="logEve">登录</el-button>
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>
</template>

<script setup>
import { User,Lock,Notebook } from "@element-plus/icons-vue";
import { ElMessage } from 'element-plus';
import { verifyUserService } from "@/api/user.js";
import { ref,onMounted } from 'vue'
import * as ww from '@wecom/jssdk'

const isDisabledReg = ref(true);
const isDisabledLog = ref(true);

const isRegister=ref(false)

const registerData=ref({
    username: '',
    password: '',
    repassword: '',
    realname: ''
})

const form=ref(null)

const checkrePassword=(rule,value,callback)=>{
    if(value!==registerData.value.password){
        callback(new Error('两次密码输入不一致'))
    }else{
        callback()
    }
}

const checkUsername=(rule,value,callback)=>{
    if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
        callback(new Error("请输入中文"));
    } else {
        if(value.length>=2&&value.length<=7){
            callback();
        }else{
            callback(new Error("名称长度必须在2-7个字符之间"));
        }
    }
}

const checkrealname=(rule,value,callback)=>{
    if (/[a-zA-z]$/.test(value) == false) {
        callback(new Error("请输入英文"));
    } else {
        if(value.length>=6&&value.length<=12){
            callback();
        }else{
            callback(new Error("账号长度必须在6-12个字符之间"));
        }
    }
}

const rules={
    username:[
        {required:true, message:"请输入账号", trigger:'blur'},
        {validator:checkrealname ,trigger:'blur'}
    ],
    password:[
        {required:true, message:"请输入密码", trigger:'blur'},
        {min: 6,max: 12, message:"密码必须在6-12个字符之间", trigger:'blur'}
    ],
    repassword:[
        {required:true, message:"请确认密码", trigger:'blur'},
        {validator:checkrePassword, trigger: 'blur'}
    ],
    realname:[
        {required:true, message:"请输入名称", trigger:'blur'},
        {validator:checkUsername, trigger:'blur'}
    ]
}

const regEve = async () =>{
    form.value.validate(
    (valid) => {
        if (valid) {
            // 处理提交逻辑
                register()
            }else{
                ElMessage.error('验证失败，请检查输入！')
            }
        }
    )
}

const logEve = async () =>{
    form.value.validate(
    (valid) => {
        if (valid) {
            // 处理提交逻辑
                login()
            }else{
                ElMessage.error('验证失败，请检查输入！')
            }
        }
    )
}

import {
    userRegisterService,
    userLoginService
} from '@/api/user.js'
import { useTokenStore } from "@/store/token";
const tokenStore=useTokenStore();

const register=async()=>{
    let result=await userRegisterService(registerData.value)
    ElMessage.success('注册成功')
}


import { useRouter } from "vue-router";
const router=useRouter()

const login=async()=>{
    let result=await userLoginService(registerData.value);
    if(result.code=='200'){
        ElMessage.success('登录成功');
        tokenStore.setToken(result.data);
        localStorage.setItem('username',registerData.value.username);
        router.push('/home')
    }
}

const clearRegisterData=()=>{
    registerData.value={
        username:'',
        password:'',
        repassword:''
    }
}

const fasterLog=async()=>{
    let result = await verifyUserService();
    console.log(tokenStore.getToken())
    if(result.code=='200'){
        router.push('/home')
    }
}
fasterLog()
</script>

<style lang="scss" scoped>
.login-page {
    height: 100vh;
    background-color: #fff;
    .bg {
        background: url('@/assets/logo.png') no-repeat 60% center / 240px auto,
            url('@/assets/login_bg.jpg') no-repeat center / cover;
        border-radius: 0 20px 20px 0;
    }

    .form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        user-select: none;

        .title{
            margin: 0 auto;
        }

        .button{
            width: 100%;
        }

        .flex{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>