<template>
    <div id="wechat">
    </div>
    <!-- <div>
      <button @click="login()">点击</button>
    </div> -->
</template>
<script setup>
import { ref,onMounted } from 'vue'
import * as ww from '@wecom/jssdk'
import { wechatLogin } from '@/api/user.js'
const inputValue = ref('')  
onMounted(() => {
  const wwLogin = ww.createWWLoginPanel({
    el: '#wechat', // 修改为模板中的ID
    params: {
      login_type: 'CorpApp',
      appid: 'ww8b29f05221264d4f',
      agentid: '1000012',
      redirect_uri: 'https://www.xiaolatech.cn',
      state: 'loginState',
      redirect_type: 'callback',
    },
    onCheckWeComLogin({ isWeComLogin }) {
      console.log(isWeComLogin)
    },
    onLoginSuccess({ code }) {
      login(code)
      console.log(code)
    },
    onLoginFail(err) {
      console.log(err)
    },
  })
  const div = document.getElementById("wechat")
  // div.append(wwLogin.el)
})
let isClickListenerAdded = false;  

const login =async(code)=>{
  const result=await wechatLogin(code);
  const flag=result.data;

  const dataToSend = {
    message: "iframe 方法已执行",
    timestamp: Date.now(),
    additionalInfo: true
  };

  window.parent.postMessage(dataToSend, "*");
}
</script>