<template>
    <div class="box">
    <el-dialog v-model="visibleDrawer" title="退补登记" width="350px" @opened="returnData()">
        <el-form ref="form" :model="retrieveData" label-width="auto" style="max-width: 600px">
            <el-form-item label="退补金额" prop="retrieveMoney">
                <el-input v-model="retrieveData.retrieveMoney"></el-input>
            </el-form-item>
            <el-form-item label="退补金额" prop="moneyModify"> 
            <el-radio-group v-model="retrieveData.moneyModify">
                <el-radio label="1">加价</el-radio>
                <el-radio label="2">减价</el-radio>
            </el-radio-group>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="retrieve()" v-if="retrieveData.retrieveRemark==0||retrieveData.retrieveRemark==null">
                登记补价
                </el-button>
            </div>
        </template>
    </el-dialog>
    </div>
</template>
<script setup>
import { ref,defineProps } from 'vue'
import {
    addOrderCheckIn
} from '@/api/order.js';
import { ElMessage,ElMessageBox } from 'element-plus';

const visibleDrawer = ref(false)

const open=()=>{
    visibleDrawer.value=true
}

const props = defineProps ({
    afterData : String
})

const retrieveData=ref({
    orderId:'',
    thirdPhone:'',
    thirdSuppliers:'',
    thirdCarrierName:'',
    thirdOrderStatus:'',
    thirdOrderCancelRemark:'',
    remark:'',
    profitMoneyDouble:'',
    purchaseMoneyDouble:'',
    actualMoneyDouble:'',
    upcCode:'',
    afterMarket:'',
    afterReason:'',
    afterMoney:'',
    retrieveRemark:'',
    retrieveMoney:'',
    moneyModify:'1',
})

const orderControll=ref({
    retrieveRemark:true,
    retrieveMoney:true
})

const returnData=()=>{
    retrieveData.value=props.afterData
    console.log(retrieveData.value)
    if(retrieveData.value.retrieveMoney>=0){
        retrieveData.value.moneyModify='1'
    }else{
        retrieveData.value.moneyModify='2'
    }
}

const retrieve=async()=>{
    if(retrieveData.value.moneyModify==2){
        retrieveData.value.retrieveMoney="-"+retrieveData.value.retrieveMoney
    }
    retrieveData.value.retrieveRemark=1;
    retrieveData.value.afterMarket=0;
    retrieveData.value.afterMoney="0";
    try{
        let result=await addOrderCheckIn(retrieveData.value)
        console.log(result)
        if(result.code==200||result.code==0){
            ElMessage.success("登记成功")
            visibleDrawer.value=false
            location.reload()
        }else{
            retrieveData.value.retrieveRemark=0;
        }
    }catch(error){
        console.log(error)
    }
}

defineExpose({open})
</script>