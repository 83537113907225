import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from '@/router'
import 'element-plus/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import "@element-plus/icons-vue"
import Big from 'big.js'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-persistedstate-plugin'
import locale from 'element-plus/dist/locale/zh-cn.js'

const app=createApp(App);
const pinia=createPinia();
const persist=createPersistedState();

pinia.use(persist)
app.use(pinia)
window.pinia=pinia

app.use(ElementPlus,{locale})
app.use(router)
app.mount('#app')
