<template>
    <div class="box">
        <el-dialog v-model="visibleDrawer" title="获取门店信息" width="700px">
            <el-form inline :model="mapMsgQuery" label-width="auto" style="max-width: 600px">
                <el-form-item label="关键词" style="width: 160px;">
                    <el-input v-model="mapMsgQuery.keyword"></el-input>
                </el-form-item>
                <el-form-item label="城市" style="width: 160px;">
                    <el-input v-model="mapMsgQuery.city"></el-input>
                </el-form-item>
                <el-form-item label="获取条数" style="width: 160px;">
                    <el-input v-model="mapMsgQuery.total"></el-input>
                </el-form-item>
                <el-form-item label="选择数据源">
                    <el-radio-group v-model="mapMsgQuery.originalData">
                        <el-radio label="1">高德</el-radio>
                        <el-radio label="2">百度</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="exportExcel()"
                    v-loading.fullscreen.lock="fullscreenLoading">
                    获取excel表
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import { 
  mapMsgService
} from '@/api/shop';
import { ElMessage } from 'element-plus';
import axios from 'axios';
import { useTokenStore } from "@/store/token";


//控制抽屉是否显示
const visibleDrawer = ref(false)
const fullscreenLoading = ref(false)

const open=()=>{
    visibleDrawer.value=true
}

const mapMsgQuery=ref({
  keyword:'手机',
  city:'',
  total:'',
  originalData:'1'
})

const exportExcel=async()=>{
    fullscreenLoading.value = true
    if(mapMsgQuery.value.total%25==0&&mapMsgQuery.value.total!=0&&mapMsgQuery.value.total!=''&&mapMsgQuery.value.total<=200){
        if(mapMsgQuery.value.city==''&&mapMsgQuery.value.keyword==''){
            ElMessage.error("请输入关键词和城市区域")
            fullscreenLoading.value = false
        }else{
        const tokenStore=useTokenStore();
            try {
                // 假设这是你的文件URL
                const fileUrl = '/api/map/addressMsgExport?keyword='+mapMsgQuery.value.keyword+'&city='+mapMsgQuery.value.city+'&total='+mapMsgQuery.value.total+'&type='+mapMsgQuery.value.originalData;
                 // 使用axios发送GET请求
                const response = await axios({
                method: 'get',
                url: fileUrl,
                headers: new Headers({
                    'Authorization':tokenStore.getToken()
                }),
                responseType: 'blob', // 重要：指示axios返回Blob对象
                });

                fullscreenLoading.value = false
                // 创建一个Blob对象
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // 创建一个指向Blob对象的URL
                const url = window.URL.createObjectURL(blob);

                // 创建一个a标签用于下载
                const link = document.createElement('a');
                link.href = url;
                // 设置文件名（可选，如果服务器没有提供）
                link.setAttribute('download', '统计数据.xlsx');

                // 触发点击事件
                document.body.appendChild(link);
                link.click();

                // 清理（可选）
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            } catch (error) {
                console.error('下载文件时出错:', error);
                fullscreenLoading.value = false
            }   
        }
    }else{
        ElMessage.error("请使用25的倍数作为读取条件,且最大查询量不可过200条")
        fullscreenLoading.value = false
    }
}

defineExpose({open})
</script>