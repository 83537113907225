<template>
    <div class="notfound">
        <div class="box">
            <!-- <img src="../assets/blank/notfound.png" alt=""> -->
            <p class="news">抱歉，你访问的页面不存在或仍在开发中</p>
            <p class="news">{{ second }}秒后跳转至登录界面</p>
        </div>
    </div>
</template>
 
<script>
import { useTokenStore } from '@/store/token';
import axios from 'axios';
export default {
    data(){
        return{
            second:2,
            flag:false
        }
    },
    mounted() {
        this.startTimer();
    },
    methods: {
        async triggerEvent() {
            await this.verify();
            if(this.flag==true){
                this.$router.push({path:'/home'})
            }else{
                this.$router.push({path:'/login'})
            }
        },
        startTimer() {
            this.timer = setInterval(() => {
                this.second--;
                if(this.second==0){
                    this.triggerEvent();
                }
            }, 1000);
        },
        async verify(){
            axios.get('/api/user/verifyToken')
            .then(res=>{
                this.flag=true
                console.log(this.flag)
            })
            .catch(err=>{
                this.flag=false
                console.log(this.flag)
            });
        }
    }
}
</script>
 
<style lang="scss" scoped>
.box{
    margin:0 auto;
    height:635px;
    width:1205px;
    text-align: center;
    img{
        display:block
    }
    .news{
        font-size:18px;
        color:#666;
        margin-bottom:50px;
    }
}
</style>