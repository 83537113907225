import request from '@/utils/request.js'
export const shopListService=(shopQueryDTO)=>{
    // const tokenStore=useTokenStore();
    return request.post('/api/shop/shopList',shopQueryDTO)
}

export const shopAppService=(TShopInfo)=>{
    return request.post('/api/shop/shopCheck',TShopInfo)
}

export const shopOpeService=(TShopInfo)=>{
    return request.post('/api/shop/shopOperate',TShopInfo)
}

export const shopUpdService=(TShopInfo)=>{
    return request.post('/api/shop/shopUpdate',TShopInfo)
}

export const JDstoreInfo=(storeCode,accountName)=>{
    return request.get('/api/shop/JDstoreInfo?storeCode='+storeCode+"&accountName="+accountName)
}

export const shopInfoService=(id)=>{
    return request.get('/api/shop/shopInfoEcho?id='+id)
}

export const checkInfoService=(id)=>{
    return request.get('/api/shop/checkInfoEcho?id='+id)
}

export const shopDelService=(id)=>{
    return request.get('/api/shop/deleteShop?id='+id)
}

export const shopAccount=()=>{
    return request.get('/api/shop/shopAccount')
}
export const mapMsgService=(mapMsgQuery)=>{
    return request.get('/api/addressMsg?keyword='+mapMsgQuery.keyword+'&city='+mapMsgQuery.city+'&total='+mapMsgQuery.total)
}