import { createRouter, createWebHistory } from 'vue-router'
import { useTokenStore } from '@/store/token';

import Login from '@/views/Login.vue'
import BaseMenu from '@/views/BaseMenu.vue'
import NotFound from '@/components/NotFound.vue'
import wechat from '@/views/WeChatLogin.vue'

import House from '@/views/House.vue'
import shopBaseInfo from '@/views/shop/shopBaseInfo.vue'
import orderBaseInfo from '@/views/order/orderBaseInfo.vue';
import pluginBaseInfo from '@/views/plugin/pluginBaseInfo.vue';


const routes = [
  {path: '/login',component: Login},
  {
    path: '/home',component: BaseMenu,
    redirect: '/house',
    children:[
      {path:'/house',component:House},
      {path:'/shop/shopBaseInfo',component:shopBaseInfo},
      {path:'/order/orderBaseInfo',component:orderBaseInfo},
      {path:'/plugin/pluginBaseInfo',component:pluginBaseInfo}
    ]
  },
  {path:'/:catchAll(.*)',component:NotFound},
  {path:'/wechat',component:wechat}
]

const router = createRouter({
  history: createWebHistory(),
  routes:routes
})



// to表示去哪个路由，from表示来自哪个路由，next表示放行
router.beforeEach((to, from, next)=>{
  const tokenStore=useTokenStore();
  const token=tokenStore.getToken();
  if(to.path !== '/login'){
    if(token!==''&&token!==null){
      next()
    }else if(to.path=='/wechat'){
      next()
    }else{
      next('/login')
    }
  }else{
    next()
  }
})

export default router
