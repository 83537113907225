<template>
    <div class="box">
        <el-dialog v-model="visibleDrawer" title="运营登记" width="700px" @opened="init()" @closed="clear()">
            <el-form :model="TShopInfo" label-width="auto" style="max-width: 600px">
                <el-form-item label="账号名称" >
                    <el-col :span="12">
                        <el-autocomplete
                        v-model="TShopInfo.account"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入内容"
                        :trigger-on-focus="false"
                        @select="handleSelect"
                         style="width: 75%; margin-right: 44px;"
                        ></el-autocomplete>
                    </el-col>
                    <el-button @click="fillInfo">读取信息</el-button>
                </el-form-item>
                <el-form-item label="门店编号">
                    <el-input v-model="TShopInfo.storeCode" placeholder="请输入门店编号"></el-input>
                </el-form-item>
                <el-form-item label="门店名称" >
                    <el-input v-model="TShopInfo.storeName" :disabled="true" placeholder="由后台读取"></el-input>
                </el-form-item>
                <el-form-item label="所在地区" >
                    <el-input v-model="TShopInfo.storeArea" :disabled="true" placeholder="由后台读取"></el-input>
                </el-form-item>
                <el-form-item label="审核状态" >
                    <el-input v-model="TShopInfo.examineView" :disabled="true" placeholder="由后台读取"></el-input>
                </el-form-item>
                <el-form-item label="启用状态">
                    <el-input v-model="TShopInfo.startStatusView" :disabled="true" placeholder="由后台读取"></el-input>
                </el-form-item>
                <el-form-item label="营业状态" >
                    <el-input v-model="TShopInfo.tradeStatusView" :disabled="true" placeholder="由后台读取"></el-input>
                </el-form-item>
                <el-form-item label="开户审核" >
                    <el-radio v-model="TShopInfo.available" label="1" @input="textControll(1)">可用</el-radio>
                    <el-radio v-model="TShopInfo.available" label="2" @input="textControll(2)">不可用</el-radio>
                </el-form-item>

                <el-form-item label="不可用因素" >
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="请输入内容"
                        v-model="TShopInfo.availableResult"
                        :disabled="orderControll.text"
                        >
                    </el-input>
                </el-form-item>
                <el-form-item label="登记日期" >
                    <el-date-picker
                        v-model="TShopInfo.operationDate"
                        type="datetime"
                        placeholder="选择日期"
                        format="YYYY/MM/DD"
                        style="width: 570px;"
                    />
                </el-form-item>
                <el-form-item label="登记人" >
                    <el-input v-model="TShopInfo.checkStatus" placeholder="请输入登记人" @keyup.enter="shopOperate"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="shopOperate()">
                    登记
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { 
    shopOpeService,
    JDstoreInfo,
    checkInfoService
} from '@/api/shop';
import { ElMessage, ElMessageBox } from 'element-plus';


//控制抽屉是否显示
const visibleDrawer = ref(false)

const selectedOptions = ref([])

const open=()=>{
    visibleDrawer.value=true
}

const props = defineProps ({
    updData : String
})

const updData=ref({
    updId:''
})

// 声明一个自定义事件 'shop-operated'
const emit = defineEmits(['shop-operated']);
defineExpose({open})

const TShopInfo = ref({
    id:'',
    account: '',
    storeCode: '',
    storeName: '',
    storeArea: '',
    examine: '',
    startStatus: '',
    tradeStatus: '',
    checkStatus: '',
    examineView: '',
    startStatusView: '',
    tradeStatusView: '',
    province: '',
    city: '',
    county: '',
    available: '1',
    availableResult: '',
    operationDate: ''
})

const accountList = ref([])


const clear=()=>{
    TShopInfo.value.account= '',
    TShopInfo.value.storeCode= '',
    TShopInfo.value.storeName= '',
    TShopInfo.value.storeArea= '',
    TShopInfo.value.examine= '',
    TShopInfo.value.startStatus= '',
    TShopInfo.value.tradeStatus= '',
    TShopInfo.value.checkStatus= '',
    TShopInfo.value.examineView= '',
    TShopInfo.value.startStatusView= '',
    TShopInfo.value.tradeStatusView= ''
}

const fillInfo=async()=>{
    if(TShopInfo.value.account!=''&&TShopInfo.value.storeCode!=''){
        const result=await JDstoreInfo(TShopInfo.value.storeCode,TShopInfo.value.account)
        console.log(result.data)
        TShopInfo.value.storeName=result.data.storeName
        TShopInfo.value.storeArea=result.data.storeArea
        TShopInfo.value.examineView=result.data.examineView
        TShopInfo.value.startStatusView=result.data.startStatusView
        TShopInfo.value.tradeStatusView=result.data.tradeStatusView
        TShopInfo.value.province=result.data.province
        TShopInfo.value.city=result.data.city
        TShopInfo.value.county=result.data.county
    }
}

const shopOperate=async()=>{
    TShopInfo.value.operationDate=formatDate(TShopInfo.value.operationDate);
    TShopInfo.value.shopArea=selectedOptions.value[0]+selectedOptions.value[1]
    const result=await shopOpeService(TShopInfo.value);
    if(result.code=="200"){
        visibleDrawer.value=false
        ElMessage.success("登记成功")
        emit('shop-operated', { message: '登记成功', data: result.data });
    }
}

const init=async()=>{
    updData.value=props.updData
    TShopInfo.value.id=updData.value.updId
    accountList.value=updData.value.accountList
    const result=await checkInfoService(TShopInfo.value.id)

    TShopInfo.value.account=result.data.account
    TShopInfo.value.storeCode=result.data.storeCode
    TShopInfo.value.operationDate=result.data.operationDate
    TShopInfo.value.checkStatus=result.data.checkStatus
}


const querySearch=(queryString, cb) => {
    console.log(accountList.value)
    var results = queryString ? accountList.value.filter(createFilter(queryString)) : accountList.value;
    // 调用 callback 返回建议列表的数据
    cb(results);
}

const createFilter=(queryString) => {
    return (accountList) => {
        return (accountList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    };
}


const handleSelect = (item) => {
  TShopInfo.value.storeName=item
//   console.log(TShopInfo.value.storeName);
};

const orderControll=ref({
    text:true
})

const textControll = (num) => {
    console.log(num)
    switch(num){
        case 1:
            orderControll.value.text=true
            TShopInfo.value.availableResult=''
            break;
        case 2:
            orderControll.value.text=false
            break;
    }
}

const formatDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();

    // JavaScript 月份是从 0 开始的，所以需要加 1
    var month = ("0" + (date.getMonth() + 1)).slice(-2);

    // 获取日期，如果小于 10 则前面补 0
    var day = ("0" + date.getDate()).slice(-2);

    // 拼接成目标格式
    var string=year + "-" + month + "-" + day;
    return string
}
</script>

<style lang="scss" scoped>
.box {
    :deep(){
        .el-dialog__header {
            line-height: 1px;
            text-align: left;
            height: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ccc;
            margin-bottom: 30px;
        }
    }
}

.avatar-uploader {
    :deep() {
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }

        .el-upload {
            border: 1px dashed var(--el-border-color);
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            transition: var(--el-transition-duration-fast);
        }

        .el-upload:hover {
            border-color: var(--el-color-primary);
        }

        .el-icon.avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            text-align: center;
        }
    }
}
.el-form{
    .el-input{
        width: 570px;
    }
    .el-select{
        width: 570px;
    }
}
</style>