import { defineStore } from 'pinia'
import {ref} from 'vue'

/*
参数1：token,名字表示唯一性。
参数2：函数，函数内部可定义状态的所有内容

返回值：函数
*/
export const useTokenStore=defineStore(
  'token',
  ()=>{
    const token=ref('')

    const setToken=(newToken)=>{
      token.value=newToken
    }

    const getToken=()=>{
      return token.value
    }

    const removeToken=()=>{
      token.value=''
    }

    return {
      token,getToken,setToken,removeToken
    }
  },{
    persist: true
  }
);

